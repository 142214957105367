// Copyright 2019 Descript, Inc

import { Team } from './Teams';

export enum ErrorCategory {
    Account = 'account',
    AddMediaAsset = 'add-media-asset',
    Agent = 'agent',
    AiActions = 'ai-actions',
    AISpeech = 'ai-speech',
    Alignment = 'alignment',
    Analytics = 'analytics',
    /** generic app architecture, like keyboard shortcuts, sagas, react error boundaries */
    AppArchitecture = 'app-architecture',
    AppBoot = 'app-boot',
    AppSettings = 'app-settings',
    AudioEdit = 'audio-edit',
    Auth = 'auth',
    Avatars = 'avatars',
    Beamcoder = 'beamcoder',
    Cancellation = 'cancellation',
    Comments = 'comments',
    CopyPaste = 'copy-paste',
    CorrectionWizard = 'correction-wizard',
    CreateRevision = 'create-revision',
    DebugTools = 'debug-tools',
    DescriptModel = 'descript-model',
    DesignSystem = 'design-system',
    DocumentReducer = 'document-reducer',
    DriveInvite = 'drive-invite',
    DriveView = 'drive-view',
    DurableJobQueue = 'durable-job-queue',
    DynFeatureFlags = 'dynamic-feature-flags',
    Export = 'export',
    HotDog = 'hotdog',
    FFmpeg = 'ffmpeg',
    FillerWords = 'filler-words',
    Fonts = 'fonts',
    Forethought = 'forethought',
    GlobalAssetSync = 'global-asset-sync',
    KeyboardShortcuts = 'keyboard-shortcuts',
    LiveTranscription = 'live-transcription',

    /** Errors specifically related to the Live Collab doc sync system */
    LiveCollab = 'live-collab',
    /** Errors related to the Live Collab presence UI */
    LiveCollabUi = 'live-collab-ui',

    /** Errors related to saving and syncing documents that are agnostic to a
     *  particular doc sync system. */
    DocSync = 'doc-sync',
    MultiwindowEditing = 'multiwindow',
    Onboarding = 'onboarding',
    Payments = 'payments',
    Paywall = 'paywall',
    Plans = 'plans',
    Playback = 'playback',
    PlaybackVideo = 'playback-video',
    PlaybackVideoShare = 'playback-video-share',
    ProjectEdit = 'project-edit',
    ProjectInvite = 'project-invite',
    ProjectLoad = 'project-load',
    ProjectPrompt = 'project-prompt',
    Publish = 'publish',
    Pubnub = 'pubnub',
    QuickRecording = 'quick-recording',
    ReactRedux = 'react-redux',
    Recording = 'recording',
    Revisions = 'revisions',
    VoiceVerification = 'voice-verification',
    ReplaceMode = 'replace-mode',
    Rooms = 'rooms',
    Roomtone = 'roomtone',
    RunSagas = 'run-sagas',
    // ScreenRecording = Legacy quick recorder
    // TODO: Remove this when removing the quick-recorder-version feature flag
    ScreenRecording = 'screen-recording',
    Script = 'script',
    Canvas = 'canvas',
    Animation = 'animation',
    EditorInteraction = 'editor-interaction',
    Templates = 'templates',
    SharePage = 'share-page',
    StudioSound = 'studio-sound',
    Support = 'support',
    BackgroundMask = 'background-mask',
    EyeContact = 'eye-contact',
    CenterActiveSpeaker = 'center-active-speaker',
    BrainBuddy = 'brain-buddy',
    LipSync = 'lip-sync',
    SubscriptionChanges = 'subscription-changes',
    SystemAudio = 'system-audio',
    Test = 'test',
    Thumbnails = 'thumbnails',
    Timeline = 'timeline',
    Transcription = 'transcription',
    TranscriptionLanguage = 'transcription-language',
    Translation = 'translation',
    Model3 = 'model-3',
    UserModalInteraction = 'user-modal-interaction',
    UnhandledException = 'unhandled-exception',
    VideoMediaEngine = 'video-media-engine',
    WebAudioRecorder = 'web-audio-recorder',
    WebRecorder = 'web-recorder',
    WebAutoBackup = 'web-auto-backup',
    InstantPlayback = 'instant-playback',
    ZoomManualImport = 'zoom-manual-import',
}

export const ERROR_CATEGORY_TEAMS: Record<ErrorCategory, Team> = Object.freeze({
    [ErrorCategory.Account]: Team.Core,
    [ErrorCategory.AddMediaAsset]: Team.Media,
    [ErrorCategory.Agent]: Team.AIActions,
    [ErrorCategory.AiActions]: Team.AIActions,
    [ErrorCategory.Alignment]: Team.EditorFramework,
    [ErrorCategory.Analytics]: Team.UserJourneys,
    [ErrorCategory.AppArchitecture]: Team.ClientPlatform,
    [ErrorCategory.AppBoot]: Team.ClientPlatform,
    [ErrorCategory.AppSettings]: Team.Core,
    [ErrorCategory.AudioEdit]: Team.Media,
    [ErrorCategory.Auth]: Team.Core,
    [ErrorCategory.Avatars]: Team.AISpeech,
    [ErrorCategory.Beamcoder]: Team.Media,
    [ErrorCategory.Cancellation]: Team.UserJourneys,
    [ErrorCategory.Comments]: Team.EditorFramework,
    [ErrorCategory.CopyPaste]: Team.EditorFramework,
    [ErrorCategory.CorrectionWizard]: Team.EditorFramework,
    [ErrorCategory.CreateRevision]: Team.Collaboration,
    [ErrorCategory.Canvas]: Team.EditorWorkflows,
    [ErrorCategory.Animation]: Team.EditorFramework,
    [ErrorCategory.EditorInteraction]: Team.EditorFramework,
    [ErrorCategory.Templates]: Team.EditorWorkflows,
    [ErrorCategory.DebugTools]: Team.Collaboration,
    [ErrorCategory.DescriptModel]: Team.EditorFramework,
    [ErrorCategory.DesignSystem]: Team.ClientPlatform,
    [ErrorCategory.DocumentReducer]: Team.EditorFramework,
    [ErrorCategory.DriveInvite]: Team.Core,
    [ErrorCategory.DriveView]: Team.Core,
    [ErrorCategory.HotDog]: Team.Recorder,
    [ErrorCategory.BrainBuddy]: Team.Recorder,
    [ErrorCategory.DurableJobQueue]: Team.Media,
    [ErrorCategory.Export]: Team.Media,
    [ErrorCategory.Forethought]: Team.UserJourneys,
    [ErrorCategory.FFmpeg]: Team.Media,
    [ErrorCategory.DynFeatureFlags]: Team.ClientPlatform,
    [ErrorCategory.FillerWords]: Team.EditorFramework,
    [ErrorCategory.Fonts]: Team.EditorFramework,
    [ErrorCategory.GlobalAssetSync]: Team.Media,
    [ErrorCategory.KeyboardShortcuts]: Team.EditorFramework,
    [ErrorCategory.LiveTranscription]: Team.EditorFramework,
    [ErrorCategory.LiveCollab]: Team.Collaboration,
    [ErrorCategory.LiveCollabUi]: Team.Collaboration,
    [ErrorCategory.DocSync]: Team.Collaboration,
    [ErrorCategory.MultiwindowEditing]: Team.Collaboration,
    [ErrorCategory.AISpeech]: Team.AISpeech,
    [ErrorCategory.Onboarding]: Team.UserJourneys,
    [ErrorCategory.Payments]: Team.Core,
    [ErrorCategory.Paywall]: Team.Core,
    [ErrorCategory.Plans]: Team.Core,
    [ErrorCategory.Playback]: Team.Media,
    [ErrorCategory.PlaybackVideo]: Team.Media,
    [ErrorCategory.PlaybackVideoShare]: Team.Media,
    [ErrorCategory.ProjectEdit]: Team.Core,
    [ErrorCategory.ProjectInvite]: Team.Core,
    [ErrorCategory.ProjectLoad]: Team.Collaboration,
    [ErrorCategory.ProjectPrompt]: Team.Core,
    [ErrorCategory.Publish]: Team.Media,
    [ErrorCategory.Pubnub]: Team.Collaboration,
    [ErrorCategory.QuickRecording]: Team.Recorder,
    // maybe CPLAT someday, but the majority of errors in this today are attributable to Editor Framework
    [ErrorCategory.ReactRedux]: Team.EditorFramework,
    [ErrorCategory.Recording]: Team.Recorder,
    [ErrorCategory.ReplaceMode]: Team.EditorWorkflows,
    [ErrorCategory.Revisions]: Team.Collaboration,
    [ErrorCategory.Rooms]: Team.Recorder,
    [ErrorCategory.RunSagas]: Team.EditorFramework,
    [ErrorCategory.VoiceVerification]: Team.AISpeech,
    [ErrorCategory.Roomtone]: Team.EditorFramework,
    [ErrorCategory.ScreenRecording]: Team.Recorder,
    [ErrorCategory.Script]: Team.EditorFramework,
    [ErrorCategory.SharePage]: Team.Recorder,
    [ErrorCategory.StudioSound]: Team.EditorFramework,
    [ErrorCategory.BackgroundMask]: Team.EditorFramework,
    [ErrorCategory.EyeContact]: Team.EditorFramework,
    [ErrorCategory.CenterActiveSpeaker]: Team.EditorWorkflows,
    [ErrorCategory.LipSync]: Team.AISpeech,
    [ErrorCategory.SubscriptionChanges]: Team.Core,
    [ErrorCategory.Support]: Team.Core,
    [ErrorCategory.SystemAudio]: Team.Recorder,
    [ErrorCategory.Test]: Team.ClientPlatform,
    [ErrorCategory.Thumbnails]: Team.EditorFramework,
    [ErrorCategory.Timeline]: Team.EditorFramework,
    [ErrorCategory.Transcription]: Team.EditorFramework,
    [ErrorCategory.TranscriptionLanguage]: Team.EditorFramework,
    [ErrorCategory.Translation]: Team.AISpeech,
    [ErrorCategory.Model3]: Team.EditorFramework,
    [ErrorCategory.UnhandledException]: Team.ClientPlatform,
    [ErrorCategory.UserModalInteraction]: Team.UserJourneys,
    [ErrorCategory.VideoMediaEngine]: Team.Media,
    [ErrorCategory.WebAudioRecorder]: Team.Recorder,
    [ErrorCategory.WebRecorder]: Team.Recorder,
    [ErrorCategory.WebAutoBackup]: Team.Collaboration,
    [ErrorCategory.InstantPlayback]: Team.Recorder,
    [ErrorCategory.ZoomManualImport]: Team.Recorder,
});
