import { Dimensions } from '../Schemas/Types';

// Copyright 2019 Descript, Inc

export const Bitrates = [32000, 48000, 64000, 96000, 128000, 160000, 192000, 256000] as const;
export type Bitrate = (typeof Bitrates)[number];
export const BitrateDefault: Bitrate = 128000;

export function bitrateToDisplayString(bitrate: Bitrate): string {
    return `${bitrate / 1000} kbps`;
}

export const Samplerates = [44100, 48000] as const;
export type Samplerate = (typeof Samplerates)[number];

export function isValidSamplerate(samplerate: number): samplerate is Samplerate {
    return Samplerates.indexOf(samplerate as Samplerate) !== -1;
}

// Peak = "peak normalization"
// Integer values are LUFS values for loudness normalization
export const Normalizations = ['Off', 'Peak', -14, -16, -18, -23, -24] as const;
export type Normalization = (typeof Normalizations)[number];
export const NormalizationDefault: Normalization = -24;

export function normalizationToDisplayString(normalization: Normalization): string {
    if (typeof normalization === 'number') {
        return `${normalization} LUFS`;
    }
    return normalization;
}

export const VideoQualities = ['Low', 'Medium', 'High'] as const;
export type VideoQuality = (typeof VideoQualities)[number];
export const VideoQualityDefault: VideoQuality = 'Medium';

export function getVideoBitrate(dimensions: Dimensions, quality: VideoQuality): number {
    let kbps: number;

    if (dimensions.height <= 720) {
        kbps = 4000;
    } else if (dimensions.height <= 1080) {
        kbps = 7000;
    } else {
        kbps = 10_000;
    }

    let qualityMultiplier: number;

    switch (quality) {
        case 'High':
            qualityMultiplier = 3;
            break;
        case 'Medium':
            qualityMultiplier = 2;
            break;
        case 'Low':
        default:
            qualityMultiplier = 1;
            break;
    }

    return Math.trunc(kbps * qualityMultiplier);
}
