// Copyright 2020 Descript, Inc
import {
    Auth0MessageParams,
    generateAuthorizationURL,
    generateLogoutURL,
    InitialScreen,
    removeLastUrl,
    setLastUrl,
} from '../Api/Auth0Client';
import { AppSettings } from './AppSettings';
import * as ApiClientBase from '../Api/ApiClientBase';
import * as ApiClient from '../Api/ApiClient';
import { fetchInviteInfo } from '../Api/InviteClient';
import { AppConstants, ModalRoutes, Routes } from './Constants';
import { trackError } from '../Utilities/ErrorTracker';
import { uuidRegexWithoutAnchorsString } from '@descript/descript-core';
import { removeAfterSignOutQueryKey } from './Constants/Auth';
import { InviteLinkQueryKey } from '../Containers/InviteLinkLandingPage/InviteLinkTypes';
import { auth0AccountLinkingLabel } from '../Utilities/AccountLinking';
import { ErrorCategory, DescriptError, Errors } from '@descript/errors';
import { isDesktop2 } from '../Desktop2';
import { getAuthProvider, AuthProvider } from '../Api/Auth/AuthProvider';
import { addRedirectToRoute } from '../Api/Auth/StytchConfig';

async function desktop2LogoutCallback({
    userInitiated,
    auth0MessageParams,
}: {
    userInitiated: boolean;
    auth0MessageParams?: Auth0MessageParams;
}) {
    // If this was initiated by the user, we should close all the other windows
    if (userInitiated) {
        globalThis.DescriptDesktopAPI?.window.closeAllOtherWindows();
        removeLastUrl();
    }

    if (window.location.pathname === ModalRoutes.auth) {
        return;
    }

    setLastUrl(window.location.href);

    const authProvider = await getAuthProvider();
    if (authProvider === undefined) {
        throw new DescriptError(
            'Could not select auth provider in desktop 2 logout',
            ErrorCategory.Auth,
        );
    }
    if (authProvider === AuthProvider.stytch) {
        // Make resilient to multiple logout calls
        if (window.location.pathname === Routes.stytchAppAuth) {
            return;
        }
        // Always show the sign in page. In the case of non-intentional logout, the
        // windows will not be closed, but just present this page.
        const lastRoute = encodeURIComponent(
            `${window.location.pathname}${window.location.search}`,
        );
        const route = addRedirectToRoute(Routes.stytchAppAuth, lastRoute);
        window.routerHistoryPushDeprecated(route);
        return;
    }

    const searchParams = new URLSearchParams({});
    if (auth0MessageParams) {
        searchParams.set(
            AppConstants.Auth.auth0MessageParamsQueryKey,
            encodeURIComponent(JSON.stringify(auth0MessageParams)),
        );
    }
    if (auth0MessageParams?.submitLabel === auth0AccountLinkingLabel) {
        searchParams.set(AppConstants.Auth.autoAccountLinkQueryKey, '1');
    }
    const authUrl = new URL(window.location.href);
    authUrl.pathname = ModalRoutes.auth;
    for (const [key, value] of searchParams) {
        authUrl.searchParams.set(key, value);
    }
    // Always show the sign in page. In the case of non-intentional logout, the
    // windows will not be closed, but just present this page.
    window.location.href = authUrl.toString();
}

export default async function webLogoutCallback({
    ctx,
    logoutOfAuth0,
    userInitiated,
    auth0MessageParams,
}: ApiClient.LogoutCallbackPayload) {
    if (isDesktop2) {
        await desktop2LogoutCallback({
            userInitiated,
            auth0MessageParams,
        });
        return;
    }

    const authProvider = await getAuthProvider();
    if (authProvider === undefined) {
        throw new DescriptError(
            'Could not select auth provider in web logout',
            ErrorCategory.Auth,
        );
    }
    if (authProvider === AuthProvider.stytch) {
        // Make resilient to multiple logout calls
        if (window.location.pathname === Routes.stytchAuthenticate) {
            return;
        }
        const lastRoute = encodeURIComponent(
            `${window.location.pathname}${window.location.search}`,
        );
        window.routerHistoryPushDeprecated(
            `${Routes.stytchAuthenticate}?${AppConstants.Auth.stytchRedirectRoute}=${lastRoute}`,
        );
        return;
    }

    const currentUrl = new URL(window.location.href);

    const inviteId = /^\/invites\/(.*)$/.exec(currentUrl.pathname)?.[1];

    const driveInviteLinkPathRegex = new RegExp(
        `^/drives/(?<driveId>${uuidRegexWithoutAnchorsString})/join$`,
    );
    const driveInviteLinkPathMatch = driveInviteLinkPathRegex.exec(currentUrl.pathname);
    const isEncryptedInviteLink = window.location.pathname === Routes.joinDriveInviteLink;
    const isInviteLinkLandingPage = !!driveInviteLinkPathMatch || isEncryptedInviteLink;

    currentUrl.searchParams.delete(InviteLinkQueryKey.token); // deprecated

    if (logoutOfAuth0) {
        // Only save state for invite landing pages
        if (inviteId || isInviteLinkLandingPage) {
            const inviteSearchParamsCopy = new URLSearchParams(currentUrl.searchParams);
            inviteSearchParamsCopy.delete(removeAfterSignOutQueryKey);

            const inviteLogoutState = JSON.stringify({
                path: currentUrl.pathname + '?' + inviteSearchParamsCopy.toString(),
            });
            window.location.href = generateLogoutURL({
                storage: AppSettings.authInstance,
                state: inviteLogoutState,
            });
        } else {
            if (auth0MessageParams) {
                const signIn = await generateAuthorizationURL({
                    storage: AppSettings.authInstance,
                    // Return to account linking when we get back from sign in
                    state:
                        auth0MessageParams?.submitLabel === auth0AccountLinkingLabel
                            ? JSON.stringify({ path: Routes.accountLinking })
                            : undefined,
                    initialScreen: InitialScreen.login,
                    auth0MessageParams,
                });
                window.location.href = generateLogoutURL({
                    storage: AppSettings.authInstance,
                    state: undefined,
                    // Return to sign in link with hints
                    returnTo: signIn,
                });
                return;
            }
            window.location.href = generateLogoutURL({ storage: AppSettings.authInstance });
        }
        return;
    }

    ApiClientBase.authLog('Load authorizationUrl (web)');

    let initialScreen = InitialScreen.login;
    if (currentUrl.searchParams.has(AppConstants.Auth.signUpHintQueryKey)) {
        if (currentUrl.searchParams.get(AppConstants.Auth.signUpHintQueryKey) === 'true') {
            initialScreen = InitialScreen.signUp;
        }
    }

    const searchParamsCopy = new URLSearchParams(currentUrl.searchParams);
    searchParamsCopy.delete(AppConstants.Auth.signUpHintQueryKey);

    const state = JSON.stringify({
        path: currentUrl.pathname + '?' + searchParamsCopy.toString(),
    });

    const loginHint = searchParamsCopy.get('login_hint') ?? undefined;

    if (isInviteLinkLandingPage) {
        const inviteLinkNonce = searchParamsCopy.get(InviteLinkQueryKey.nonce) ?? undefined; // deprecated
        const driveName = searchParamsCopy.get(InviteLinkQueryKey.driveName) ?? undefined;
        const inviteMembershipType = searchParamsCopy.get(
            InviteLinkQueryKey.inviteMembershipType,
        );

        const membershipTypeText = inviteMembershipType
            ? inviteMembershipType === 'editor'
                ? ' as an editor'
                : ' as a free member'
            : '';
        const welcomeMessage = `You've been invited to join ${
            driveName ?? 'a drive'
        }${membershipTypeText}!`;
        const submitLabel = `Join ${driveName ?? 'drive'}`;

        const inviteLinkDriveId = isEncryptedInviteLink
            ? searchParamsCopy.get(InviteLinkQueryKey.driveId)!
            : driveInviteLinkPathMatch!.groups?.driveId;

        if (!inviteLinkDriveId) {
            throw new DescriptError(
                `No driveId found in invite link at logout at path ${window.location.pathname}}`,
                ErrorCategory.Auth,
            );
        }

        window.location.href = await generateAuthorizationURL({
            storage: AppSettings.authInstance,
            state,
            initialScreen,
            auth0MessageParams: { loginHint, submitLabel, welcomeMessage },
            auth0EmailInviteParams: undefined,
            auth0LinkInviteParams: { inviteLinkNonce, inviteLinkDriveId },
        });
    } else if (inviteId) {
        const inviteNonce = searchParamsCopy.get('nonce') ?? undefined;

        let invite;
        try {
            invite = await fetchInviteInfo(ctx, { inviteId });
        } catch (e) {
            const error = e as Error;
            if (!Errors.isNotFoundError(error)) {
                trackError(error, 'web-logout-callback-invite-fetch', {
                    category: ErrorCategory.ProjectInvite,
                });
            }
            throw error;
        }
        const submitLabel = `Join ${invite.name || invite.type}`;
        const welcomeMessage = `You've been invited to join a ${invite.type}!`;
        if (!invite.inviteeExists) {
            initialScreen = InitialScreen.signUp;
        }
        window.location.href = await generateAuthorizationURL({
            storage: AppSettings.authInstance,
            state,
            initialScreen,
            auth0MessageParams: { loginHint, submitLabel, welcomeMessage },
            auth0EmailInviteParams: { inviteId, inviteNonce },
        });
    } else {
        window.location.href = await generateAuthorizationURL({
            storage: AppSettings.authInstance,
            state,
            initialScreen,
        });
    }
}
