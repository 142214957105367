// Copyright 2018 Descript, Inc

import { invariant, ErrorCategory } from '@descript/errors';
import UAParser from 'ua-parser-js';

export const enum BuildType {
    release = 'release',
    staging = 'staging',
    qa = 'qa',
    // More akin to an alternate staging build with all packaging
    testbuild = 'testbuild',
    // Dev local build
    local = 'local',
    // Automated testing in CI and local dev (e.g. Jest)
    testRunner = 'testrunner',
}

export const isMac = (): boolean => {
    return (
        typeof navigator !== 'undefined' &&
        (navigator.platform.indexOf('Mac') > -1 ||
            // jsdom doesn't have `navigator.platform` set
            (process.env.NODE_ENV === 'test' && navigator.userAgent.indexOf('darwin') > -1))
    );
};
export const isReallyMac = (): boolean => {
    // Because iPadOS 13+ Safari mimics macOS, we also check navigator.maxTouchPoints,
    // which is available only a positive number in iOS Safari.
    return (
        typeof navigator !== 'undefined' &&
        isMac() &&
        !('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0)
    );
};

export const isWindows = (): boolean => {
    return (
        typeof navigator !== 'undefined' &&
        (navigator.platform.indexOf('Win') > -1 ||
            // jsdom doesn't have `navigator.platform` set
            (process.env.NODE_ENV === 'test' && navigator.userAgent.indexOf('win32') > -1))
    );
};

export const isLinux = (): boolean => {
    return (
        typeof navigator !== 'undefined' &&
        (navigator.platform.indexOf('Linux') > -1 ||
            // jsdom doesn't have `navigator.platform` set
            (process.env.NODE_ENV === 'test' && navigator.userAgent.indexOf('linux') > -1))
    );
};

export const isIPhone = (): boolean => {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('iPhone') > -1;
};

export const isIPad = (): boolean => {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('iPad') > -1;
};

export const isAndroid = (): boolean => {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Android') > -1;
};

export const platform = (): string => {
    if (isMac()) {
        return 'mac';
    } else if (isWindows()) {
        return 'windows';
    } else if (isLinux()) {
        return 'linux';
    }
    if (typeof navigator !== 'undefined') {
        return navigator.platform;
    }

    return '';
};

export type ClientVariant = 'electron' | 'web' | 'web-share' | 'web-embed';

export function getClientVariant(): ClientVariant | undefined {
    switch (process.env.PRODUCT) {
        case 'electron':
        case 'web':
        case 'web-embed':
        case 'web-share':
            return process.env.PRODUCT;
        case 'cloud-exporter':
            return 'web';
        default:
            return undefined;
    }
}

export function isElectron(): boolean {
    const variant = getClientVariant();
    invariant(variant, 'No PRODUCT env var set', ErrorCategory.AppArchitecture);
    return variant === 'electron';
}

export function isWeb(): boolean {
    const variant = getClientVariant();
    invariant(variant, 'No PRODUCT env var set', ErrorCategory.AppArchitecture);
    return variant === 'web';
}

export function isBrowser(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !isElectron() || (process as any).type === 'renderer';
}

export function isCloudExporter(): boolean {
    return isWeb() && process.env.PRODUCT === 'cloud-exporter';
}

export const operatingSystemName = (): string | undefined => {
    if (isMac()) {
        return 'Mac';
    } else if (isWindows()) {
        return 'Windows';
    }
    return undefined;
};

export function clientID(): string {
    return isElectron() ? 'electron.descript.com' : 'web.descript.com';
}
export const bundleID = 'com.descript.beachcube';
export function clientName(): string {
    return isElectron() ? 'Descript Electron' : 'Descript Web';
}

export const buildType: BuildType = (() => {
    if (process.env.NODE_ENV === 'test') {
        return BuildType.testRunner;
    }

    const runtimeEnv = process.env.RUNTIME_ENV;
    const reactAppBuildType = process.env.REACT_APP_BUILD_TYPE;

    if (runtimeEnv) {
        switch (runtimeEnv) {
            case 'production':
                return BuildType.release;
            case 'staging':
                return BuildType.staging;
            case 'qa':
                return BuildType.qa;
            default:
                break;
        }
    }

    if (reactAppBuildType) {
        switch (reactAppBuildType) {
            case 'release':
                return BuildType.release;
            case 'staging':
                return BuildType.staging;
            case 'testbuild':
                return BuildType.testbuild;
            case 'local':
                return BuildType.local;
            default:
                break;
        }
    }

    // fallback
    return BuildType.local;
})();

export const isDevBuild: boolean =
    buildType === BuildType.local ||
    buildType === BuildType.staging ||
    buildType === BuildType.testbuild;

// Keyboard Information

export function cmdKey(): string {
    return isWindows() ? 'Ctrl' : 'Cmd';
}

export function altKey(): string {
    return isWindows() ? 'Alt' : 'Opt';
}

export function fullAltKey(): string {
    return isWindows() ? 'Alt' : 'Option';
}

export function backspaceKey(): string {
    return isWindows() ? 'Backspace' : 'Delete';
}

export function getOS(): string {
    if (process.env.PRODUCT === 'electron') {
        const os = require('os') as { platform: () => string };

        let version: string | undefined;

        if (os.platform() === 'darwin') {
            version = (require('macos-version') as () => string)();
        }

        if (version) {
            return version;
        }
    }

    const parser = new UAParser();
    const currentPlatform = parser.getResult();

    return currentPlatform.os?.version ?? 'unknown';
}

export function getBrowser(): string {
    try {
        const parser = new UAParser();
        return parser.getBrowser().name?.toLowerCase() ?? 'unknown';
    } catch {
        return 'unknown';
    }
}
