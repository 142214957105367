import {
    AuthFlowType,
    B2BOAuthProviders,
    B2BProducts,
    StyleConfig,
    StytchB2BUIClient,
    StytchB2BUIConfig,
} from '@stytch/vanilla-js/b2b';
import { createStytchB2BUIClient } from '@stytch/nextjs/b2b/ui';
import { AppConstants, Routes } from '../../App/Constants';
import * as ApiTarget from '../ApiTarget';
import { DescriptError, ErrorCategory } from '@descript/errors';
import { getWebUrlBase } from '../../Utilities/getShareUrlBase';
import { getAuthProvider } from './AuthProvider';
import { trackError } from '../../Utilities/ErrorTracker';

// Can't use our utility.css classes here because these values aren't added as
// `class` attributes, but as the values of css key/value pairs. Because we're
// still using var colors, dark mode works on component load.
export const stytchStyleConfig: StyleConfig = {
    container: {
        backgroundColor: 'var(--chrome-0-elevated)',
        // This is reused for the border of the Organization buttons
        borderColor: 'var(--grey-100-a)',
        borderRadius: '8px',
        width: '390px',
    },
    colors: {
        primary: 'var(--grey-800)',
        secondary: 'var(--grey-400)',
        success: 'var(--green-500)',
        error: 'var(--red-500)',
    },
    buttons: {
        primary: {
            backgroundColor: 'var(--grey-800)',
            textColor: 'var(--grey-0)',
            borderColor: 'transparent',
            borderRadius: '8px',
        },
        secondary: {
            backgroundColor: 'var(--grey-0)',
            textColor: 'var(--grey-800)',
            borderColor: 'var(--grey-100-a)',
            borderRadius: '8px',
        },
        disabled: {
            backgroundColor: 'var(--grey-0)',
            textColor: 'var(--grey-800)',
            borderColor: 'var(--grey-100-a)',
            borderRadius: '8px',
        },
    },
    inputs: {
        backgroundColor: 'var(--grey-30-a)',
        textColor: 'var(--grey-800)',
        placeholderColor: 'var(--grey-100-a)',
        borderColor: 'var(--grey-100-a)',
        borderRadius: '8px',
    },
    fontFamily: '"Inter"',
    hideHeaderText: true,
};

interface StytchConfig {
    publicToken: string;
    discoveryRedirectURLBase: string;
    apiAudience: string;
    clientIdDesktop1: string;
    clientIdDesktop2: string;
}

type StytchEnvs = {
    [key in ApiTarget.ApiTenancy]: StytchConfig;
};

const stytchEnvs: StytchEnvs = {
    production: {
        publicToken: 'public-token-live-3316ba4f-7e4c-4152-97e9-7a43c4cc1a28',
        discoveryRedirectURLBase: `${getWebUrlBase()}${Routes.stytchAuthenticate}`,
        apiAudience: 'https://api.descript.com',
        clientIdDesktop1: 'NA',
        clientIdDesktop2: 'idp-client-test-81115506-3d03-4c2d-bc90-f938df2ef7fe',
    },
    staging: {
        publicToken: 'public-token-test-46ecce30-53b2-4aae-ae6b-4a4017e129d2',
        discoveryRedirectURLBase: `${getWebUrlBase()}${Routes.stytchAuthenticate}`,
        apiAudience: 'https://staging-api.descript.com',
        clientIdDesktop1: 'NA',
        clientIdDesktop2: 'idp-client-test-81115506-3d03-4c2d-bc90-f938df2ef7fe',
    },
    dev: {
        publicToken: 'public-token-test-dd0a71dd-ed1f-4da9-9aa0-282c4f728f9d',
        discoveryRedirectURLBase: `${getWebUrlBase()}${Routes.stytchAuthenticate}`,
        apiAudience: 'https://dev-api.descript.com',
        clientIdDesktop1: 'NA',
        clientIdDesktop2: 'idp-client-test-81115506-3d03-4c2d-bc90-f938df2ef7fe',
    },
    localhost: {
        publicToken: 'public-token-test-8a3033ba-3ae6-475b-b5cc-c380069bcfc8',
        discoveryRedirectURLBase: `${getWebUrlBase()}${Routes.stytchAuthenticate}`,
        apiAudience: 'https://api.descript.com',
        clientIdDesktop1: 'NA',
        clientIdDesktop2: 'idp-client-test-81115506-3d03-4c2d-bc90-f938df2ef7fe',
    },
};

/**
 * WARNING: We can grab config once at module load time (see index.tsx) because
 * ApiTarget changes cause the app to reload.
 */
export function getStytchConfig() {
    const apiTarget = ApiTarget.targetName();
    const apiTenancy = ApiTarget.getApiTenancy(apiTarget);
    return stytchEnvs[apiTenancy];
}

export function addRedirectToRoute(route: string, redirectRoute: string | undefined) {
    if (redirectRoute) {
        const encoded = redirectRoute.includes('/')
            ? encodeURIComponent(redirectRoute)
            : redirectRoute;
        return `${route}?${AppConstants.Auth.stytchRedirectRoute}=${encoded}`;
    }
    return route;
}

export function getStytchUIConfig({
    redirectRoute,
}: {
    redirectRoute?: string;
} = {}): StytchB2BUIConfig {
    const { discoveryRedirectURLBase } = getStytchConfig();
    const discoveryRedirectURL = addRedirectToRoute(discoveryRedirectURLBase, redirectRoute);
    return {
        products: [B2BProducts.oauth, B2BProducts.emailMagicLinks],
        sessionOptions: { sessionDurationMinutes: 60 },
        oauthOptions: {
            providers: [{ type: B2BOAuthProviders.Google }],
            discoveryRedirectURL,
        },
        emailMagicLinksOptions: { discoveryRedirectURL },
        authFlowType: AuthFlowType.Discovery,
    };
}

export class StytchClient {
    private static client: StytchB2BUIClient;

    public static installClient(ssr?: boolean) {
        ApiTarget.loadUserSetting();
        const { publicToken } = getStytchConfig();

        // If we are server side rendering, we should us the nextjs client, in
        // react, we use the react client
        if (!StytchClient.client) {
            if (ssr) {
                StytchClient.client = createStytchB2BUIClient(publicToken);
            } else {
                StytchClient.client = new StytchB2BUIClient(publicToken);
            }
            // AuthProvider cache warming
            getAuthProvider().catch((e) => {
                trackError(e, 'cache-warm-auth-provider', { category: ErrorCategory.Auth });
            });
        }

        return StytchClient.client;
    }

    public static getClient(): StytchB2BUIClient {
        if (!StytchClient.client) {
            throw new DescriptError('No client', ErrorCategory.Auth);
        }

        return StytchClient.client;
    }
}

// temp code so we dont screw up our environments as we get staging/prod etc deployed
export function isSafeTenancy() {
    const apiTarget = ApiTarget.targetName();
    const apiTenancy = ApiTarget.getApiTenancy(apiTarget);

    return apiTenancy === 'localhost';
}
