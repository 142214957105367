/*
 * types.ts
 * descript-web-v2
 *
 * Copyright © 2022 Descript, Inc. All rights reserved.
 */

import type { EventEmitter } from 'events';
import type { StrictEventEmitter } from 'strict-event-emitter-types';
import type { LocalStore, SyncEvent } from 'trimerge-sync';

type IsoDate = string;

export enum MergeStrategy {
    MultiTimeline = 'multi-timeline',
    Branch = 'branch',
}

export type ClientInfo = {
    id: string;
    timestamp: IsoDate;

    /** these are unset until the commit has been stored locally. */
    storeId?: string;
    index?: number;

    /** how old the commit was in milliseconds when it was sent to the server. used to estimate a userTime for accurate ordering in version history */
    timeOffset?: number;

    /** If true, the commit was sent by background sync. */
    backgroundSync?: boolean;
};

export type BackupInfo = {
    projectId: string;
    created?: IsoDate;
};

export type AppInfo = {
    version: string;
    name: string;
};

export type ServerInfo = {
    userId?: string;
    index: number;
    timestamp: IsoDate;
    main: boolean;
    /** timestamp from the client when the commit was created */
    userTime?: IsoDate;
};

export type EditInfo = BaseEditInfo | ServerEditInfo | ClientEditInfo;

/** A shared type with the backend. TODO @sylvie consolidate with backend defs. */
export interface BaseEditInfo {
    message: string;
    hash?: string;
    source?: EditSource;
    asyncEditType?: AsyncEditType;
    /** TODO: SYN 666 - cleanup merge stored in twice in commit metadata */
    merge?: MergeInfo;
    strategy?: MergeStrategy;
}

/** A shared type with the backend. TODO @sylvie consolidate with backend defs. */
export interface ServerEditInfo extends BaseEditInfo {
    source: 'server';
    type: AsyncEditType;
}

export function isServerEdit(edit: EditInfo): edit is ServerEditInfo {
    return 'source' in edit && edit.source === 'server';
}

/** A shared type with the backend. TODO @sylvie consolidate with backend defs.
 */
export enum AsyncEditType {
    Unknown = 'UNKNOWN',
    Zapier = 'ZAPIER',
    EditInDescript = 'EID',
    Overdub = 'OVERDUB',
}

export type EditSource =
    | 'user' // This edit happened as a result of a direct user action.
    | 'client' // This edit was made by the client but it did not happen as a direct result of a user action, e.g. document migration.
    | 'server'; // This edit was made on the server.

export interface ClientEditInfo extends BaseEditInfo {
    actionType?: string;
    actionPayload?: unknown;
    reduxLastRef?: string;
}

export function isClientEdit(edit: EditInfo): edit is ClientEditInfo {
    return 'actionType' in edit;
}

export type MergeInfo = {
    rootRef?: string;
    precedence: 'base' | 'merge';
    forceMergePaths?: string[];
    mergeError?: string;
};

export type CommitMetadata = {
    edit: EditInfo;
    client?: ClientInfo;
    backup?: BackupInfo;
    app?: AppInfo;
    server?: ServerInfo;
    merge?: MergeInfo;
};

export type ServerCommitMetadata = CommitMetadata & {
    server: ServerInfo;
};

export type DeltaType = string;

export type DescriptSyncEvent<CursorState> = SyncEvent<CommitMetadata, DeltaType, CursorState>;
export type DescriptLocalStore<CursorState> = LocalStore<
    CommitMetadata,
    DeltaType,
    CursorState
>;

export const TrimergeEvent = 'trimerge-event';

interface DescriptSyncEvents<CursorState> {
    [TrimergeEvent]: DescriptSyncEvent<CursorState>;
}

export type TrimergeSyncEventEmitter<CursorState> = StrictEventEmitter<
    EventEmitter,
    DescriptSyncEvents<CursorState>
>;

export type InitAuth = {
    userId: string;
};

export function isInitAuth(obj: unknown): obj is InitAuth {
    return (obj as InitAuth).userId !== undefined;
}

interface Success<T> {
    success: true;
    result: T;
}

interface Failure {
    success: false;
}

export type Result<T> = Success<T> | Failure;

export const FAILURE: Failure = { success: false } as const;
