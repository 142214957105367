import * as FeatureFlagsClient from '../FeatureFlagsClient';
import { errorCategoryContext } from '@descript/analytics';
import { ErrorCategory } from '@descript/errors';
import { trackError } from '../../Utilities/ErrorTracker';
import { useEffect, useState } from 'react';
import { PlatformHelpers } from '@descript/descript-core';

const defaultCtx = errorCategoryContext(ErrorCategory.Auth);

export enum AuthProvider {
    auth0 = 'auth0',
    stytch = 'stytch',
}

const authProviderFlagName = 'auth-provider';
let cachedAuthProvider: { provider: AuthProvider; timestamp: number } | undefined = undefined;
let lastAttempt: number | undefined = undefined;
let providerPromise: Promise<AuthProvider | undefined> | undefined = undefined;

async function getAuthProviderFlag(): Promise<AuthProvider | undefined> {
    // Regular feature flags switch to fixtures in unit and e2e tests. We copy
    // that behavior here.
    if (
        PlatformHelpers.buildType === PlatformHelpers.BuildType.testRunner ||
        DescriptFeatures.UI_TEST
    ) {
        return AuthProvider.auth0;
    }

    return await FeatureFlagsClient.fetchPublicFeatureFlag({
        ctx: defaultCtx(),
        flagName: authProviderFlagName,
        retryCount: 0,
    }).then((res) => res.value as AuthProvider);
}

async function updateAuthProvider(): Promise<AuthProvider | undefined> {
    try {
        // One at a time, please! If one of these is in flight, we wait.
        if (providerPromise) {
            return await providerPromise;
        }

        // In case of errors, don't spam the flag endpoint, wait 60 seconds
        if (lastAttempt && Date.now() - lastAttempt < 1000 * 60) {
            return undefined;
        }

        // Public flag endpoint. No retries, no auth.
        lastAttempt = Date.now();
        const promise = getAuthProviderFlag();
        providerPromise = promise;

        const remoteFlag = await promise;
        if (!remoteFlag) {
            providerPromise = undefined;
            return undefined;
        }
        cachedAuthProvider = { provider: remoteFlag, timestamp: Date.now() };
        providerPromise = undefined;
        return remoteFlag;
    } catch (e) {
        trackError(e as Error, 'get-auth-provider', {
            category: ErrorCategory.Auth,
        });
        providerPromise = undefined;
        return undefined;
    }
}

/**
 * Returns what auth provider to use from our single source of truth stored as a
 * global in this file.
 *
 * We don't mix this with redux or UserSettings as those values can be cached
 * and not agree with the value you fetch more proactively here or even with
 * itself across process boundaries.
 *
 * WARNING: you can not use flag overrides, use Debug -> Developer Options on web
 * or the env var on D1.
 */
export async function getAuthProvider(): Promise<AuthProvider | undefined> {
    const cached = cachedAuthProvider?.provider;

    // If we don't have a cached value, we wait for it
    if (!cached) {
        return await updateAuthProvider();
    }

    // If the cached value is stale, we refetch it in the background
    const maxAge = 1000 * 60 * 10; // 10 minutes
    if (cachedAuthProvider && cachedAuthProvider.timestamp > maxAge) {
        updateAuthProvider().catch(() => {
            // Handled in updateAuthProvider
        });
    }

    // Whether we background refetch or not, we return the cached value
    return cached;
}

/**
 * Hook to get the auth provider as quickly as possible. Returns undefined until
 * the auth provider is available.
 */
export function useAuthProvider(): AuthProvider | undefined {
    const [authProvider, setAuthProvider] = useState<AuthProvider | undefined>();

    // Populate the first time
    useEffect(() => {
        if (!authProvider) {
            getAuthProvider()
                .then((value) => {
                    if (value) {
                        setAuthProvider(value);
                    }
                })
                .catch((e) => {
                    trackError(e as Error, 'use-auth-provider', {
                        category: ErrorCategory.Auth,
                    });
                });
        }
    }, [authProvider]);

    // Keep up to date
    useEffect(() => {
        const interval = setInterval(
            async () => {
                const newProvider = await getAuthProvider();
                if (newProvider && newProvider !== authProvider) {
                    setAuthProvider(newProvider);
                }
            },
            1000 * 60 * 10,
        ); // 10 minutes
        return () => clearInterval(interval);
    }, [authProvider]);

    return authProvider;
}
