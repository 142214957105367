// Copyright 2024 Descript, Inc
import { getFeatureFlagMaybe } from '../Browser/FeatureFlags/reduxSelectors';
import { useFeatureFlag } from '../Browser/FeatureFlags/useFeatureFlag';
import { GlobalState } from '../Reducers';

const isE2ETest = process.env.UI_TEST === 'true';

/**
 * Gates the quick picker, canvas scene toolbar, and custom layout management options for Layouts 2.1.
 * Since we're running a backtest, serve the new exp to both excluded-from-experiment and treatment groups.
 */
export function useNewLayoutsDesign() {
    const inTreatmentGroup =
        useFeatureFlag('exp-workflows-layouts-2.1-release') === 'treatment';
    const inExcludedFromExpGroup =
        useFeatureFlag('exp-workflows-layouts-2.1-release') === 'excluded-from-experiment';
    const autoOpen =
        useFeatureFlag('exp-workflows-layouts-2.1-release') === 'treatment-auto-open';
    return !isE2ETest && (inTreatmentGroup || inExcludedFromExpGroup || autoOpen);
}

export function getShouldUseNewLayoutsDesignWithAutoOpen(state: GlobalState) {
    return (
        getFeatureFlagMaybe(state, 'exp-workflows-layouts-2.1-release') ===
        'treatment-auto-open'
    );
}

export function getShouldUseNewLayoutsDesign(state: GlobalState) {
    return (
        !isE2ETest &&
        (getFeatureFlagMaybe(state, 'exp-workflows-layouts-2.1-release') === 'treatment' ||
            getFeatureFlagMaybe(state, 'exp-workflows-layouts-2.1-release') ===
                'excluded-from-experiment')
    );
}

// if using the new layouts 2.1 design, rename 'template' to 'layout pack'
// should account for pluralization and capitalization
export function useTemplateRename() {
    const isNewLayoutsDesignEnabled = useNewLayoutsDesign();
    const renameFn = (name: string) => {
        if (!isNewLayoutsDesignEnabled) {
            return name;
        }

        return name
            .replace(/\btemplate\b/g, 'layout pack')
            .replace(/\btemplates\b/g, 'layout packs')
            .replace(/\bTemplate\b/g, 'Layout pack')
            .replace(/\bTemplates\b/g, 'Layout packs');
    };

    return { getTemplateCopy: renameFn };
}

export function getTemplateRename(name: string, titleCase = false) {
    return name
        .replace(/\btemplate\b/g, 'layout pack')
        .replace(/\btemplates\b/g, 'layout packs')
        .replace(/\bTemplate\b/g, `Layout ${titleCase ? 'Pack' : 'pack'}`)
        .replace(/\bTemplates\b/g, `Layout ${titleCase ? 'Pack' : 'pack'}`);
}
