// Copyright 2022 Descript, Inc

import { AISpeechType } from '@descript/descript-model';

// App
export const App_CheckForUpdate = 'App.checkForUpdate';

export const ExportMediaSaga_ExportCompositionAsMedia =
    'ExportMediaSaga.exportCompositionAsMedia';
export const ExportTimelineSaga_ExportCompositionAsTimeline =
    'ExportTimelineSaga.exportCompositionAsTimeline';
export const Redux_Dispatch = 'Redux.dispatch';
export const Redux_ActionsBatch = 'Redux.actionsBatch';
export const DriveView_PageLoad = 'DriveView.page.load';
export const DriveView_RowLoad = 'DriveView.row.load';
export const DriveView_FetchLocalUserData = 'DriveView.fetchLocalUserData';
export const DriveView_FetchUserData = 'DriveView.fetchUserData';
export const DriveView_SwitchDrives = 'DriveView.switchDrives';
export const DriveView_CreateNewProject = 'DriveView.createNewProject';
export const Editor_ExportBackup = 'Editor.exportBackup';
export const FlattenTracksSaga_FlattenTracks = 'FlattenTracksSaga.flattenTracks';
export const FileSaga_ConvertFile = 'FileSaga.convertFile';
export const FileSaga_ReplaceFile = 'FileSaga.replaceFile';

// Recorder Workflow V2 spans:
export const EditorRecorder_Setup = 'Editor.recorderV2.setup';
export const EditorRecorder_Recording = 'Editor.recorderV2.recording';
export const EditorRecorder_Import = 'Editor.recorderV2.import';
export const WebRecorder_Session = 'WebRecorder.session';
export const WebRecorder_SetupInputs = 'WebRecorder.setupInputs';
export const WebRecorder_InitRecorder = 'WebRecorder.initRecorder';
export const WebRecorder_StartWorkflow = 'WebRecorder.startWorkflow';
export const WebRecorder_Setup = 'WebRecorder.setup';
export const WebRecorder_CreateAsset = 'WebRecorder.createAsset';
export const WebRecorder_CreateArtifact = 'WebRecorder.createArtifact';
export const WebRecorder_SignalTrack = 'WebRecorder.signalTrack';
export const WebRecorder_DataAvailableSetup = 'WebRecorder.dataAvailableSetup';
export const WebRecorder_DataAvailableUpload = 'WebRecorder.dataAvailableUpload';
export const WebRecorder_ReadMetadata = 'WebRecorder.readMetadataDuration';
export const WebRecorder_ReifyArtifact = 'WebRecorder.reifyArtifactDuration';
export const WebRecorder_CommitArtifact = 'WebRecorder.commitArtifact';
export const WebRecorder_WaitForWorkflow = 'WebRecorder.waitForWorkflow';
export const WebRecorder_AutoRecovery = 'WebRecorder.autoRecovery';
export const WebRecorder_RecoveryDownload = 'WebRecorder.recoveryDownload';
export const WebRecorder_RecoverySession = 'WebRecorder.recoverySession';
export const WebRecorder_InstantPlayback = 'WebRecorder.instantPlayback';
export const QuickRecorder_CommitArtifact = 'QuickRecorder.postRecord.timeToCommitArtifact';
export const QuickRecorder_UploadWait = 'QuickRecorder.postRecord.timeToUpload';
export const QuickRecorder_ReadMetadata = 'QuickRecorder.postRecord.readMetadataDuration';
export const QuickRecorder_ReifyArtifact = 'QuickRecorder.postRecord.reifyArtifactDuration';
// End of Recorder Workflow V2 spans

export const LiveCollab_PersistDocument = 'live_collab.persist_document';
export const LiveCollab_InvalidState = 'live_collab.invalid_state';

// Live Collab commit life cycle and child spans
export const LiveCollab_CommitLifeCycle = 'LiveCollab.commitLifeCycle';
export const LiveCollab_CommitLifeCycle_create = 'LiveCollab.commitLifeCycle_create';
export const LiveCollab_CommitLifeCycle_localSave = 'LiveCollab.commitLifeCycle_localSave';
export const LiveCollab_CommitLifeCycle_remoteSend = 'LiveCollab.commitLifeCycle_remoteSend';
export const LiveCollab_CommitLifeCycle_remoteAck = 'LiveCollab.commitLifeCycle_remoteAck';

// Potential Live Collab hotspots
export const LiveCollab_Diff = 'LiveCollab.diff';
export const LiveCollab_Merge = 'LiveCollab.merge';
export const LiveCollab_Patch = 'LiveCollab.patch';

// Load Project parent and child spans
export const Editor_LoadProject = 'Editor.loadProject';
export const Editor_LoadProject_fetchProject = 'Editor.loadProject.fetchProject';
export const Editor_LoadProject_connectLiveCollab = 'Editor.loadProject.connectLiveCollab';
export const Editor_LoadProject_readyLocalRead = 'Editor.loadProject.localReadReady';
export const Editor_LoadProject_readyRemoteRead = 'Editor.loadProject.remoteReadReady';
// End of Load Project spans

export const Editor_DocumentValidity = 'Editor.documentValidity';
export const Editor_GetEnergies = 'Editor.getEnergies';
export const AlignmentSaga_ProcessAlignmentJob = 'AlignmentSaga.processAlignmentJob';
export const LiveTranscription_Workflow = 'Live.transcription.workflow';
export const AISpeech_CreateTTS = 'AISpeech.createTTS';
export const AISpeech_CreateOverdub = 'AISpeech.createOverdub';
export const AISpeech_CreateRegenerate = 'AISpeech.createRegenerate';
export const getAISpeechTraceNames = (type: AISpeechType) => {
    if (type === 'consentedImputation') {
        return { metricName: 'AISpeechSaga.generateOverdub', spanName: AISpeech_CreateOverdub };
    } else if (type === 'tts') {
        return { metricName: 'AISpeechSaga.generateTTS', spanName: AISpeech_CreateTTS };
    } else {
        return { metricName: 'AISpeechSaga.regenerate', spanName: AISpeech_CreateRegenerate };
    }
};
export const AISpeechSaga_ApiGeneration = 'ImputationSaga.apiGeneration';
export const TemplateProjectSaga_ApplyTemplate = 'TemplateProjectSaga.applyTemplateToCard';
export const TemplateProjectSaga_InsertCardFromTemplate =
    'TemplateProjectSaga.insertCardFromTemplate';
export const TemplateProjectSaga_SaveCardToTemplate = 'TemplateProjectSaga.saveCardToTemplate';
export const Template_LoadPreview = 'Template.loadPreview';
export const Transcription = 'Transcription';
export const PublishSaga_StartPublishContent = 'PublishSaga.startPublishContent';
export const PublishSaga_ExecutePublishContent = 'PublishSaga.executePublishContent';
export const YouTube_StartPublish = 'YouTube.startPublish';
export const GoogleDrive_StartPublish = 'GoogleDrive.startPublish';

// Recording
export const EditorRecorder_Workflow = 'Editor.recorder.workflow';
export const EditorRecorder_StandaloneWorkflow = 'Editor.recorder.standalone.workflow';
export const Recorder_RecoveryWorkflow = 'Recorder.recovery.workflow';
export const Recorder_ZoomImportWorkflow = 'Recorder.zoom_import.workflow';
export const Recorder_ZoomAccountLinkWorkflow = 'Recorder.zoom_import.zoom_link';

// Web Media
export const WebMedia_AddMedia = 'WebMedia.addMedia';
export const WebMedia_ReadMediaMetadata = 'WebMedia.readMediaMetadata';
export const WebMedia_CreateAsset = 'WebMedia.createAsset';
export const WebMedia_CreateArtifact = 'WebMedia.createArtifact';
export const WebMedia_CreatePlaceholderArtifact = 'WebMedia.createPlaceholderArtifact';
export const WebMedia_CalculateMd5 = 'WebMedia.calculateMd5';
export const WebMedia_UploadArtifact = 'WebMedia.uploadArtifact';
export const WebMedia_CreateTranscribable = 'WebMedia.createTranscribable';
export const WebMedia_SyncMetadata = 'WebMedia.syncMetadata';
export const WebMedia_SyncMetadata_FirstSync = 'WebMedia.syncMetadata.firstSync';
export const WebMedia_ExamineFileGops = 'WebMedia.examineFileGops';

// Playback
export const PlaybackSaga_StartPlayback = 'PlaybackSaga.startPlayback';
export const PlaybackSaga_SeekToPlayback = 'PlaybackSaga.seekToPlayback';
export const PlaybackSaga_Scrub = 'PlaybackSaga.scrub';

// API
export const ApiClient_Reauthorize = 'ApiClient.reauthorize';
export const ApiClient_Request = 'ApiClient.request';
export const ApiClient_BlobRequest = 'ApiClient.blobRequest';
export const ApiClient_RawResponseRequest = 'ApiClient.rawResponseRequest';
export const ApiClient_PublicApiRequest = 'ApiClient.publicApiRequest';
export const Fetch = 'fetch';

// AI Feature Processing
export const AIFeature_StudioSound = 'AIFeature.studioSound';
export const AIFeature_BackgroundRemoval = 'AIFeature.backgroundRemoval';
export const AIFeature_EyeContact = 'AIFeature.eyeContact';
export const AIFeature_SpeakerFocus = 'AIFeature.speakerFocus';
export const AIFeature_Diarization = 'AIFeature.diarization';
export const AIFeature_RoomTone = 'AIFeature.roomTone';
export const AIFeature_CorrectionWizard = 'AIFeature.correctionWizard';
export const AIFeature_LipSync = 'AIFeature.lipSync';

export const AddMediaFiles = 'Editor.addMediaFiles';
export const AddMediaFile = 'Editor.addMediaFile';
export const AddMediaFilesToDocument = 'Editor.addMediaFilesToDocument';
export const GainCalculationSaga_AddDefaultGainToFile =
    'GainCalculationSaga.calculateDefaultGainForFile';

// AI Speech
export const AISpeech_voiceCreated = 'AISpeech.voiceCreated';
export const AISpeech_voiceAuthorization = 'AISpeech.voiceAuthorization';

// Onboarding
export const Onboarding_started = 'Onboarding.started';
export const Onboarding_finalized = 'Onboarding.completed';
export const Onboarding_standard_prefix = 'Onboarding.standard';
export const Onboarding_other_complete = 'Onboarding.other.complete';
export const Onboarding_pricing_subscription = 'Onboarding.pricing.subscription';
export const Onboarding_pricing_complete = 'Onboarding.pricing.complete';
export const Onboarding_createDrive_complete = 'Onboarding.createDrive.complete';
export const Onboarding_inviteTeam_complete = 'Onboarding.inviteTeam.complete';
export const Onboarding_personalize_complete = 'Onboarding.personalize.complete';
export const Onboarding_aiSpeechSetup_complete = 'Onboarding.aiSpeechSetup.complete';
export const Onboarding_dataConsent_complete = 'Onboarding.dataConsent.complete';
export const Onboarding_tryRecorder_complete = 'Onboarding.tryRecorder.complete';

// Data Consent
export const DataConsent_get = 'DataConsent.get';
export const DataConsent_set = 'DataConsent.set';

// Plans and payments
export const ManageSubscriptionModal_load = 'ManageSubscriptionModal.load';
export const ManageSubscriptionModal_submit = 'ManageSubscriptionModal.submitted';
export const ManageSubscriptionModal_close = 'ManageSubscriptionModal.close';
export const NonDriveOwnerModal_load = 'NonDriveOwnerModal.load';
export const NonDriveOwnerModal_close = 'NonDriveOwnerModal.close';
export const ManageIncompleteSubscriptionModal_load = 'ManageIncompleteSubscriptionModal.load';
export const ManageIncompleteSubscriptionModal_submit =
    'ManageIncompleteSubscriptionModal.submit';
export const ManageIncompleteSubscriptionModal_close =
    'ManageIncompleteSubscriptionModal.close';
export const EditCreditCardModal_load = 'EditCreditCardModal.load';
export const EditCreditCardModal_submit = 'EditCreditCardModal.submit';
export const EditCreditCardModal_close = 'EditCreditCardModal.close';
export const PlansAndPayments_SetupPaymentMethod = 'PlansAndPayments.SetupPaymentMethod';
export const PlansAndPayments_UpdateDriveSubscription =
    'PlansAndPayments.UpdateDriveSubscription';
export const PlansAndPayments_CreateNewDrive = 'PlansAndPayments.CreateNewDrive';
export const PlansAndPayments_PaymentElementNotLoaded =
    'PlansAndPayments.PaymentElementNotLoaded';
export const PlansAndPayments_RequestTaxPercentages = 'PlansAndPayments.RequestTaxPercentages';
export const PlansAndPayments_UpdatePlan = 'PlansAndPayments.UpdatePlan';

export const PlansPage_load = 'PlansPage_load';

export const CheckoutPage_load = 'CheckoutPage_load';
export const CheckoutPage_submit = 'CheckoutPage_submit';

export const UpdatePaymentMethodPage_load = 'UpdatePaymentMethodPage.load';
export const UpdatePaymentMethodPage_submit = 'UpdatePaymentMethodPage.submit';

export const CreateNewDrivePage_load = 'CreateNewDrivePage.load';

export const InvitesForNewDrivePage_load = 'InvitesForNewDrivePage.load';
export const InvitesForNewDrivePage_complete = 'InvitesForNewDrivePage.complete';

export const SubscriptionSettingsRedesign_load = 'SubscriptionSettingsRedesign.load';

export const OutOfTranscriptionMinutesModal_load = 'OutOfTranscriptionMinutesModal.load';
export const OutOfTranscriptionMinutesModal_upgradePressed =
    'OutOfTranscriptionMinutesModal.upgradePressed';
export const OutOfTranscriptionMinutesModal_close = 'OutOfTranscriptionMinutesModal.close';

export const Onboarding_pricing_skippedDueToPlansLoadError =
    'OnboardingPricing.SkippedDueToPlansLoadError';

// Web vitals
export const WebVitals_INP = 'WebVitals.interactionToNextPaint';

export const Media_RenderThumbnail = 'Media.RenderThumbnail';
