// Copyright 2018 Descript, Inc

import { TextRange } from '../Schemas/IntegerRange';
import { CharacterSet, whitespaceAndNewlines, wordCharacters } from './CharacterSet';
import { RangeCache } from '../Performance/RangeCache';
import { isEmpty, lowerBound, upperBound } from '../Objects/Common/IntegerRanges';
import { DiffResult } from './Diffs';
import { clamp } from '@descript/descript-core';

export function lengthOfCommonPrefix(a: string, b: string): number {
    // Unexpectedly faster than the linear approach
    // https://neil.fraser.name/news/2007/10/09/?
    let min = 0;
    let max = Math.min(a.length, b.length);
    let mid = max;
    while (min < mid) {
        if (a.substring(min, mid) === b.substring(min, mid)) {
            min = mid;
        } else {
            max = mid;
        }
        mid = Math.floor((max - min) / 2 + min);
    }
    return mid;
}

export function lengthOfCommonSuffix(a: string, b: string): number {
    // Unexpectedly faster than the linear approach
    // https://neil.fraser.name/news/2007/10/09/?
    let min = 0;
    let max = Math.min(a.length, b.length);
    let mid = max;
    while (min < mid) {
        if (a.substring(a.length - mid) === b.substring(b.length - mid)) {
            min = mid;
        } else {
            max = mid;
        }
        mid = Math.floor((max - min) / 2 + min);
    }
    return mid;
}

export function countCharacter(
    str: string,
    char: string,
    startIndex: number = 0,
    endIndex: number | undefined = undefined,
): number {
    let start = startIndex;
    let count = 0;
    while ((start = str.indexOf(char, start) + 1) !== 0) {
        if (endIndex !== undefined && start > endIndex) {
            break;
        }
        count++;
    }
    return count;
}

export function utf8ByteLength(str: string): number {
    // https://stackoverflow.com/a/23329386/1006238
    // returns the byte length of an utf8 string
    let s = str.length;
    for (let i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) {
            s += 1;
        } else if (code > 0x7ff && code <= 0xffff) {
            s += 2;
        }
        if (code >= 0xdc00 && code <= 0xdfff) {
            i--; // trail surrogate
        }
    }
    return s;
}

export function expandLocationToBoundaries(
    boundaryCharacterSet: CharacterSet,
    text: string,
    location: number,
): TextRange | undefined {
    const textLength = text.length;

    if (location > textLength) {
        return RangeCache.get(textLength, 0);
    } else if (location < 0) {
        return RangeCache.get(0, 0);
    }

    if (location === 0 || boundaryCharacterSet.contains(text.charAt(location - 1))) {
        return RangeCache.get(location, 0);
    }

    if (location === textLength || boundaryCharacterSet.contains(text.charAt(location))) {
        return RangeCache.get(location, 0);
    }

    let startLocation = location;
    let endLocation = location;

    while (
        endLocation < textLength &&
        !boundaryCharacterSet.contains(text.charAt(endLocation))
    ) {
        endLocation += 1;
    }

    while (
        startLocation > 0 &&
        !boundaryCharacterSet.contains(text.charAt(startLocation - 1))
    ) {
        startLocation -= 1;
    }

    if (endLocation !== startLocation) {
        return RangeCache.get(startLocation, endLocation - startLocation);
    }
    return undefined;
}

export function expandRangeToBoundaries(
    boundaryCharacterSet: CharacterSet,
    text: string,
    range: TextRange,
): TextRange | undefined {
    const r1 = expandLocationToBoundaries(boundaryCharacterSet, text, range.location);
    const r2 = expandLocationToBoundaries(boundaryCharacterSet, text, upperBound(range));
    if (r1 && r2) {
        const length = upperBound(r2) - r1.location;
        if (length > 0) {
            return RangeCache.get(r1.location, length);
        }
    }
    return undefined;
}

export function expandLocationToWordRange(
    text: string,
    location: number,
): TextRange | undefined {
    return expandLocationToBoundaries(whitespaceAndNewlines, text, location);
}

export function expandRangeToWordRange(text: string, range: TextRange): TextRange | undefined {
    return expandRangeToBoundaries(whitespaceAndNewlines, text, range);
}

/**
 * Expands a range forwards to include a full word for deletion.
 */
export function expandRangeForWordDeletion(
    text: string,
    textRange: TextRange,
): TextRange | undefined {
    // Expand the range forward until we hit a word character.
    const expandedNonWordRange = expandRangeToBoundaries(wordCharacters, text, textRange);
    if (!expandedNonWordRange) {
        return undefined;
    }
    let expandedEndLocation = upperBound(expandedNonWordRange);

    // Then expand until we reach the end of the word.
    const expandedWordRange = expandRangeToBoundaries(
        whitespaceAndNewlines,
        text,
        RangeCache.get(expandedEndLocation, 1),
    );
    expandedEndLocation = expandedWordRange
        ? upperBound(expandedWordRange)
        : expandedEndLocation;

    // Expand the range backwards to get the start of the word.
    const expandedToStartOfWordRange = expandRangeToBoundaries(
        whitespaceAndNewlines,
        text,
        RangeCache.get(lowerBound(textRange), 1),
    );
    const expandedStartLocation = expandedToStartOfWordRange
        ? Math.min(lowerBound(expandedToStartOfWordRange), textRange.location)
        : textRange.location;

    return RangeCache.getStartEnd(expandedStartLocation, expandedEndLocation);
}

/**
 * Expands a range backwards to include a full word for deletion.
 */
export function expandRangeForWordBackspace(
    text: string,
    textRange: TextRange,
): TextRange | undefined {
    // Expand the range backwards until we hit a word character.
    const expandedNonWordRange = expandRangeToBoundaries(wordCharacters, text, textRange);
    if (!expandedNonWordRange) {
        return undefined;
    }
    let expandedStartLocation = lowerBound(expandedNonWordRange);

    // Then expand until we reach the start of the word.
    const expandedWordRange = expandRangeToBoundaries(
        whitespaceAndNewlines,
        text,
        RangeCache.get(Math.max(0, expandedStartLocation - 1), 1),
    );
    expandedStartLocation = expandedWordRange
        ? lowerBound(expandedWordRange)
        : expandedStartLocation;

    // Expand the range forwards to get the end of the word.
    const expandedToEndOfWordRange = expandRangeToBoundaries(
        whitespaceAndNewlines,
        text,
        RangeCache.get(upperBound(textRange) - 1, 1),
    );
    const expandedEndLocation = expandedToEndOfWordRange
        ? Math.max(upperBound(expandedToEndOfWordRange), upperBound(textRange))
        : textRange.location;

    return RangeCache.getStartEnd(expandedStartLocation, expandedEndLocation);
}

/**
 * Returns a text range adjusted to remove whitespace from both ends of the string.
 */
export function trimRange(
    text: string,
    range: TextRange,
    trimCharacterSet: CharacterSet = whitespaceAndNewlines,
    side: 'both' | 'start' | 'end' = 'both',
): TextRange {
    // Adjust range to remove whitespace from end
    while (
        side !== 'start' &&
        !isEmpty(range) &&
        trimCharacterSet.contains(text.charAt(upperBound(range) - 1))
    ) {
        range = RangeCache.get(range.location, range.length - 1);
    }

    // Adjust range to remove whitespace from start
    while (
        side !== 'end' &&
        !isEmpty(range) &&
        trimCharacterSet.contains(text.charAt(lowerBound(range)))
    ) {
        range = RangeCache.get(lowerBound(range) + 1, range.length - 1);
    }

    return range;
}

/**
 * Returns whether the string has a character from
 * `xSet` before a character from `ySet`, starting at `index`.
 *
 * If `forward` is false, starts at `index` and looks backwards.
 * So, this returns whether the final character from `xSet` is after
 * the final character from `ySet`.
 */
export function hasCharacterXBeforeY(
    xSet: CharacterSet,
    ySet: CharacterSet,
    text: string,
    index: number | undefined,
    forward: boolean,
): boolean {
    index = clamp(index !== undefined ? index : forward ? 0 : text.length, 0, text.length);
    const range = forward
        ? RangeCache.get(index, text.length - index)
        : RangeCache.get(0, index);
    const xIndex = xSet.index(text, range, !forward);
    if (xIndex === undefined) {
        return false;
    }
    const yIndex = ySet.index(text, range, !forward);
    if (yIndex === undefined) {
        return true;
    }
    if (forward && xIndex > yIndex) {
        return false;
    } else if (!forward && xIndex < yIndex) {
        return false;
    }
    return true;
}

export function hasWordCharacterBeforeSpaceCharacter(
    text: string,
    index: number,
    forward: boolean,
): boolean {
    return hasCharacterXBeforeY(wordCharacters, whitespaceAndNewlines, text, index, forward);
}

export function isAtWordBoundary(text: string, index: number, forward: boolean): boolean {
    if (!hasWordCharacterBeforeSpaceCharacter(text, index, forward)) {
        return true;
    }
    return !hasWordCharacterBeforeSpaceCharacter(text, index, !forward);
}

export function paragraphBoundsForIndex(text: string, index: number): TextRange {
    let start = 0;
    if (index > 0) {
        const prevNewlineIndex = text.lastIndexOf('\n', index - 1);
        if (prevNewlineIndex >= 0) {
            start = prevNewlineIndex + 1;
        }
    }

    let end = text.indexOf('\n', index);
    if (end === -1) {
        end = text.length;
    }
    return RangeCache.get(start, end - start);
}

export function* getIndexesOf(
    str: string,
    searchString: string,
    offset: number = 0,
): IterableIterator<number> {
    const len = str.length;
    const searchLen = searchString.length;
    while (offset < len) {
        offset = str.indexOf(searchString, offset);
        if (offset === -1) {
            break;
        }
        yield offset;
        offset += searchLen;
    }
}

export function simpleDiff(prev: string, next: string): DiffResult {
    if (prev === next) {
        return {
            loc: prev.length,
            removed: 0,
            added: 0,
        };
    }

    const prefixLength = lengthOfCommonPrefix(prev, next);
    const possibleSuffixPrevStr = prev.substring(prefixLength);
    const possibleSuffixStr = next.substring(prefixLength);
    const suffixLength = lengthOfCommonSuffix(possibleSuffixPrevStr, possibleSuffixStr);
    return {
        loc: prefixLength,
        removed: prev.length - prefixLength - suffixLength,
        added: next.length - prefixLength - suffixLength,
    };
}

/**
 * Computes a diff of the prev and next strings, ensuring that the beginning and end
 * of the changed range are chars in `boundaryCharacterSet`
 * @param prev
 * @param next
 * @param boundaryCharacterSet
 */
export function simpleDiffWithBoundaries(
    prev: string,
    next: string,
    boundaryCharacterSet: CharacterSet,
): DiffResult {
    const { loc, removed } = simpleDiff(prev, next);
    if (loc === prev.length && loc === next.length) {
        return {
            loc,
            removed: 0,
            added: 0,
        };
    }

    let boundaryLocBeforePrefix = Math.max(0, loc - 1);
    while (
        boundaryLocBeforePrefix > 0 &&
        boundaryLocBeforePrefix < prev.length &&
        !boundaryCharacterSet.contains(prev.charAt(boundaryLocBeforePrefix))
    ) {
        boundaryLocBeforePrefix -= 1;
    }

    let boundaryLocAfterSuffix = loc + removed;
    while (
        boundaryLocAfterSuffix >= 0 &&
        boundaryLocAfterSuffix < prev.length &&
        !boundaryCharacterSet.contains(prev.charAt(boundaryLocAfterSuffix))
    ) {
        boundaryLocAfterSuffix += 1;
    }
    boundaryLocAfterSuffix = Math.min(prev.length, boundaryLocAfterSuffix + 1);

    const suffixLength = prev.length - boundaryLocAfterSuffix;

    return {
        loc: boundaryLocBeforePrefix,
        removed: prev.length - boundaryLocBeforePrefix - suffixLength,
        added: next.length - boundaryLocBeforePrefix - suffixLength,
    };
}

/**
 * Generates indices that split the string into chunks that contain
 * at least one character from `insideSet` and end with one or more
 * characters from `boundarySet`
 * The last chunk will contain at least one character from `insideSet`
 * but might not end with characters from `boundarySet`
 */
export function* getSplitIndices(
    str: string,
    insideSet: CharacterSet,
    boundarySet: CharacterSet,
): IterableIterator<number> {
    let startIndex = 0;
    const len = str.length;

    // find: word, then terminal chars. cut after last terminal chars
    while (startIndex < len) {
        const insideInd = insideSet.index(str, RangeCache.getStartEnd(startIndex, len));
        if (insideInd === undefined) {
            return;
        }
        if (startIndex > 0) {
            yield startIndex;
        }
        let boundaryIndex = boundarySet.index(str, RangeCache.getStartEnd(insideInd + 1, len));
        if (boundaryIndex === undefined) {
            return;
        }
        boundaryIndex += 1;
        while (boundaryIndex < len && boundarySet.contains(str.charAt(boundaryIndex))) {
            boundaryIndex += 1;
        }
        if (boundaryIndex >= len) {
            return;
        }

        startIndex = boundaryIndex;
    }
}

export function replaceAll(
    str: string,
    characterSet: CharacterSet,
    replacement: string,
): string {
    let result = '';
    let start = 0;
    const len = str.length;
    while (start < len) {
        const index = characterSet.index(str, RangeCache.getStartEnd(start, len));
        if (index === undefined) {
            result += str.substring(start);
            break;
        }
        result += str.substring(start, index) + replacement;
        start = index + 1;
    }
    return result;
}
