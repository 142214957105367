// Copyright 2023 Descript, Inc

import { AppConstants } from '../App/Constants';
import * as NUserSettings from '../App/UserSettings';
import { PlatformHelpers, getAppVersion } from '@descript/descript-core';

/**
 * Attributes used for feature flag gating rules
 */
export type ClientAttributes = {
    /**
     * Unique ID identifying the particular client installation
     */
    app_id: string;
    app_build_type: PlatformHelpers.BuildType;
    /**
     * Like '11.0.0'
     */
    app_version: string;
    /**
     * Like '20210326.10'. Local builds always have '0'
     */
    app_build_number: string;

    app_name?: PlatformHelpers.ClientVariant;
};

/**
 * Get attributes used for feature flag gating
 */
export function getClientAttributes(): ClientAttributes {
    // This lets us override app_id so we can test the auth migration from Auth0
    // to Stytch.
    const appIdOverride = PlatformHelpers.isWeb()
        ? localStorage.getItem(AppConstants.stytchInstallIdKey)
        : undefined;
    const appId = NUserSettings.Application.installId.get();

    return {
        app_id: appIdOverride || appId,
        app_build_type: PlatformHelpers.buildType,
        app_version: getAppVersion(),
        app_build_number: process.env.REACT_APP_BUILD_NUMBER || '0',
        app_name: PlatformHelpers.getClientVariant(),
    };
}

/**
 * NOTE: if you add new headers here, you must also add them to the list of accepted
 * headers in the cors.ts file in the server repo.
 */
export function getClientAttributeHeaders(): Record<string, string> {
    const attrs: ClientAttributes = getClientAttributes();
    const headers: Record<string, string> = {
        'x-descript-app-id': attrs.app_id,
        'x-descript-app-build-type': attrs.app_build_type,
        'x-descript-app-version': attrs.app_version,
        'x-descript-app-build-number': attrs.app_build_number,
    };

    if (attrs.app_name) {
        headers['x-descript-app-name'] = attrs.app_name;
    }

    return headers;
}
