// Copyright 2023 Descript, Inc
import { PlatformHelpers } from '@descript/descript-core';
import type { PublishWebTargetName } from '../Api/PublishedProjectClient';
import { DescriptLinks, Hosts } from '../App/Constants';
import * as NUserSettings from '../App/UserSettings';

/** Get the share page URL base for the current ENV.
 *  Fallback to the environment where the project was published if target is not provided.
 */
export function getShareUrlBase(
    target: PublishWebTargetName | undefined = NUserSettings.Application.publishWebTarget.get(),
): string {
    return (
        (target === 'production' && Hosts.webShare) ||
        (target === 'qa' && Hosts.qaWebShare) ||
        (target === 'staging' && Hosts.stagingWebShare) ||
        (target === 'staging-api' && Hosts.stagingApiWebShare) ||
        (target === 'dev-api' && Hosts.devApiWebShare) ||
        (process.env.DEV_SERVER_SSL === 'true' && Hosts.secureDevWebShare) ||
        Hosts.devWebShare
    );
}

/** Get the web page URL base for the current ENV. */
export function getWebUrlBase(target = process.env.REACT_APP_BUILD_TYPE): string {
    return (
        (process.env.IS_PREVIEW_WEB_DEPLOY && window.location.origin) ||
        ((target === 'release' || target === 'production') && Hosts.web) ||
        (target === 'qa' && Hosts.qaWeb) ||
        (target === 'staging' && Hosts.stagingWeb) ||
        (process.env.DEV_SERVER_SSL === 'true' && Hosts.secureDevWeb) ||
        Hosts.devWeb
    );
}

export function getSharePageUrl(urlSlug: string, target?: string): string {
    if (!target) {
        target =
            process.env.UI_TEST === 'true'
                ? 'production'
                : PlatformHelpers.buildType === 'release'
                  ? 'production'
                  : PlatformHelpers.buildType === 'staging'
                    ? 'staging'
                    : PlatformHelpers.buildType === 'qa'
                      ? 'qa'
                      : PlatformHelpers.buildType === 'testbuild'
                        ? 'qa'
                        : PlatformHelpers.buildType === 'testrunner'
                          ? 'qa'
                          : PlatformHelpers.buildType === 'local'
                            ? 'local'
                            : 'production'; // fallback to prod
    }

    return `${getShareUrlBase(target as PublishWebTargetName)}/view/${urlSlug}`;
}

export function useSharePageUrl(urlSlug?: string): string {
    return urlSlug ? getSharePageUrl(urlSlug) : DescriptLinks.descriptDotCom;
}
